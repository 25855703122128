<template>
  <v-footer class="footer d-flex flex-column pt-10">
    <v-row class="text-center text-white footer-row">
      <v-col
          cols="12"
          md="4">
        <div class="lista-navegacao-container">
          <div class="ml-5">
            <v-container class="mb-5">
              <NuxtImg
                  format="webp"
                  loading="lazy"
                  alt="Logo Sempre Internet"
                  height="48"
                  placeholder
                  src="/logo-sempre-negativa.webp"
                  width="200">
              </NuxtImg>
            </v-container>
            <p>
              Somos uma operadora de
              telecomunicações, autorizada pela
              Agência Nacional de Telecomunicações (Anatel)
              a prestar serviços de
              Comunicação Multimídia (SCM) e que
              busca prover soluções customizadas
              para você e sua empresa.
            </p>
          </div>
        </div>
      </v-col>
      <v-col
          cols="12"
          md="4">
        <div class="lista-navegacao-container">
          <v-list
              class="lista-navegacao bg-transparent"
              role="list">
            <v-list-item-title
                class="mb-2 font-weight-bold text-secondary"
                role="listitem">
              <h3>MAPA DO SITE</h3>
            </v-list-item-title>
            <v-list-item
                v-for="item in navItens"
                :key="item.titulo"
                role="listitem">
              <v-list-item-title class="font-weight-light">
                <a
                    :aria-label="`Acessar ${item.url}`"
                    :href="item.url"
                    :target="item.url.includes('https://') ? '_blank' : ''">
                  {{ item.titulo }}
                </a>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </v-col>
      <v-col
          cols="12"
          md="4">
        <div class="lista-navegacao-container">
          <div class="ml-5 text-secondary">
            <h3>
              SIGA-NOS
            </h3>
            <div class="mt-3">
              <a v-for="redeSocial of redesSociais"
                 :aria-label="`${redeSocial.nome} da Sempre Internet`"
                 :href="redeSocial.url"
                 target="_blank">
                <v-icon class="mx-2"
                        size="30">
                  {{ redeSocial.icone }}
                </v-icon>
              </a>
            </div>
            <h3 class="mt-5">
              ENTRE EM CONTATO
            </h3>
            <div class="my-3">
              <a href="https://api.whatsapp.com/send?phone=558003000800&text=Olá, acessei o site e gostaria de um atendimento!"
                 target="_blank">
                <v-icon :aria-label="`Central de Atendimento da Sempre Internet`"
                        class="mx-2"
                        size="30">
                  mdi-whatsapp
                </v-icon>
                CENTRAL DE ATENDIMENTO
              </a>
            </div>
            <h3 class="mt-5">
              ESTA EMPRESA TEM O APOIO DO BNDES
            </h3>
            <div class="mt-5">
              <NuxtImg
                    format="webp"
                    loading="lazy"
                    alt="Banco Nacional de Desenvolvimento Econômico e Social"
                    placeholder
                    width="234"
                    src="/bndes_horizontal.gif">
                </NuxtImg>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="footer-row mt-5">
      <v-col
          class="text-white"
          cols="12"
          md="4">
        <div class="lista-navegacao-container">
          <div class="ml-5">
            <div class="d-flex flex-row justify-start">
              <v-icon>mdi-account-group</v-icon>
              <h3 class="ml-1">SUPORTE TÉCNICO</h3>
            </div>
            <p class="font-24 my-2">
              0800 300 0800
            </p>
            <p class="font-14">
              Segunda à Sexta: 8H às 22H.<br>
              Sábados, Domingos e feriados: 8H às 18H.
            </p>
          </div>
        </div>
      </v-col>
      <v-col
          class="text-white"
          cols="12"
          md="4">
        <div class="lista-navegacao-container">
          <div class="ml-5">
            <div class="d-flex flex-row justify-start">
              <v-icon>mdi-headset</v-icon>
              <h3 class="ml-1">
                CANAIS DE ATENDIMENTO
              </h3>
            </div>
            <p class="font-14 my-3">
              Na Sempre você tem diversas maneiras para falar. Clique e escolha a melhor.
            </p>
            <div class="my-5">
              <v-btn
                  color="white"
                  href="/canais-de-atendimento"
                  size="large"
                  style="color: white !important;
                  text-decoration: none !important;"
                  variant="outlined">
                Consultar Canais
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
      <v-col
          class="text-white"
          cols="12"
          md="4">
        <div class="lista-navegacao-container">
          <div class="ml-5">
            <div class="d-flex flex-row justify-start">
              <v-icon>mdi-store</v-icon>
              <h3 class="ml-1">
                SEDE ADMINISTRATIVA
              </h3>
            </div>
            <p class="font-14 my-2">
              Av. Governador Valadares, 737, 6º andar,
              Edifício Cephas Workcenter, Centro, Betim -
              Minas Gerais. CEP.: 32600-135
              De Segunda à Sexta: 8H às 18H.
            </p>
            <p>
              <strong>
                Não realizamos atendimento presencial na Sede Administrativa.
              </strong>
            </p>
            <div class="my-5">
              <v-btn
                  color="white"
                  href="/nossas-lojas"
                  size="large"
                  style="color: white !important;
                  text-decoration: none !important;"
                  variant="outlined">
                Consultar Lojas
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="footer-row">
      <v-col
          class="text-center text-white"
          cols="12">
        <div class="my-5">
          <p class="font-14">
            ® 2001 - {{new Date().getFullYear()}} Sempre Internet. Todos os direitos reservados. Razão Social: SEMPRE TELECOMUNICACOES LTDA.
            CNPJ: 24605227000129.
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row class="footer-row">
      <v-col
          class="text-start text-white"
          cols="12">
        <div class="my-5">
          <p class="font-12">
            * Consulte disponibilidade técnica, plano e condições gerais.
            Serviço, datas e velocidades sujeitos a estudo de viabilidade técnica,
            as mesmas podem ser alteradas devido a requisitos técnicos.
            Valores promocionais, condicionados à fidelização do cliente por no mínimo 12 meses.
            Planos e valores aplicáveis a clientes sem restrição creditícia.
            O roteador Wi-Fi está incluso, sem custo adicional.
            Não isenta pagamento de taxa de adesão.
            A contratação do serviço gera mensalidade que será cobrada normalmente,
            assim como a multa em caso de rescisão fora do período de contratação.
            Estas multas se aplicam apenas aos planos com fidelidade contratada.
            Taxa de upload conforme a tecnologia aplicada.
            Oferta válida para regiões de cobertura da Sempre Internet.
            Para mais informações ligue 0800 300 0800.
          </p>
        </div>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script lang="ts">
import type {NavItem, RedeSocial} from "~/pages/home-cliente.vue";

export default defineComponent({
  data() {
    return {
      redesSociais: [
        {
          icone: 'mdi-facebook',
          url: 'https://www.facebook.com/sempreinternetoficial',
          nome: 'Facebook'
        },
        {
          icone: 'mdi-instagram',
          url: 'https://www.instagram.com/sempreinternet/',
          nome: 'Instagram'
        },
        {
          icone: 'mdi-youtube',
          url: 'https://www.youtube.com/sempreinternet',
          nome: 'Youtube'
        }
      ] as RedeSocial[],
      navItens: [
        {
          titulo: 'HOME',
          url: '/',
        },
        {
          titulo: 'CONHEÇA A SEMPRE',
          url: '/institucional',
        },
        {
          titulo: 'QUERO CONTRATAR',
          url: 'https://planos.sempreinternet.com.br/promocao/ads/escolha-sua-cidade?_gl=1*1udk5mm*_ga*MjExMTEzODkyOS4xNzA1MjQxOTMz*_ga_3HB4F90RYJ*MTcxMzM1OTc3Ni4yMy4xLjE3MTMzNjMxNzIuMC4wLjA.*_gcl_au*NzE5MTYzNDcxLjE3MTMxODAyMDE.',
        },
        {
          titulo: 'BANDA LARGA PJ',
          url: 'https://conteudo.sempreinternet.com.br/banda-larga-pj',
        },
        {
          titulo: 'CORPORATIVO',
          url: 'https://conteudo.sempreinternet.com.br/corporativo',
        },
        {
          titulo: 'CANAIS DE ATENDIMENTO',
          url: '/canais-de-atendimento',
        },
        {
          titulo: 'NOSSAS LOJAS',
          url: '/nossas-lojas',
        },
        {
          titulo: 'ÁREA DO CLIENTE',
          url: '/home-cliente',
        },
        {
          titulo: 'REGULAMENTOS E CONDIÇÕES GERAIS',
          url: '/regulamentos',
        },
        {
          titulo: 'FAQ',
          url: '/faq',
        },
        {
          titulo: 'POLÍTICA DE PRIVACIDADE',
          url: '/politica-de-privacidade',
        },
        {
          titulo: 'TESTE DE VELOCIDADE',
          url: 'https://www.speedtest.net/',
        },
        {
          titulo: 'TRABALHE CONOSCO',
          url: 'https://trabalhenasempre.vagas.solides.com.br/',
        },
        {
          titulo: 'OUVIDORIA',
          url: '/ouvidoria',
        },
      ] as NavItem[],
    }
  }
})
</script>
<style scoped>
.footer {
  background-image: linear-gradient(180deg, rgb(var(--v-theme-primary)) 0%, rgb(var(--v-theme-primary-darken-3)) 100%);
}

.footer-row {
  width: 100%;
  border-bottom: 1px solid white;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-24 {
  font-size: 24px;
}

.lista-navegacao {
  padding-left: 20px;
  font-size: 15px !important;
}

.lista-navegacao-container {
  padding: 30px 0;
  text-align: start;
  height: 100%;
  border-left: 1px solid white;
}

.v-list-item--density-default.v-list-item--one-line {
  min-height: unset !important;
}

a:-webkit-any-link {
  color: white !important;
  transition: color linear 0.2s !important;
  text-decoration: none !important;
}

a:-webkit-any-link:hover {
  color: rgb(var(--v-theme-secondary)) !important;
  text-decoration: underline !important;
}

.v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line {
  padding-inline: 0 !important;
}
</style>
